
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.my-home-btn button{
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar{
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  justify-content: space-around;
  align-items: center;
  font-family: 'Combo', 'cursive';
}

.logo{
  grid-column-start: 2;
  grid-column-end: 3;
}
.dark-mode{
  grid-column-start: 3;
  grid-column-end: 4;
}
.my-list{
  grid-column-start: 6;
  grid-column-end: 10;
}

.active {
  color: #000;
}

.navbar img{
  margin-top: 10px;
  width: 9vh;
  cursor: pointer;
  border-radius: 100%;
}

.navbar img:hover{
  opacity: .8;
}



.my-list ul{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  width: auto;
  text-align: center;

}

@media (max-width: 800px) {
  .logo{
    display: none;
  } 
  .my-list ul{
    width: 100%;
  }
  .dark-mode{
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .my-list{
    grid-column-start: 2;
    grid-column-end: 7;
  }
  
}

.my-list ul li a{
  transition: 1s;
}
.my-list ul li a::after{
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: rgb(0, 195, 255);
  transition: width .5s;
}
.my-list ul li a:hover::after{
  width: 100%;
}

.my-list ul li a:hover{
  color: rgb(0, 195, 255);
}

.navbar li{
  cursor: pointer;

}

.navbar a{
  color: rgb(0, 0, 0);
  text-decoration: none;
}



.sec_navbar{
  background-color: antiquewhite;
  min-height: 40px;
  display: none;
}

.menu{
  height: 140px;
}

.menu-buttons{
  display: flex;
  justify-content: space-between;
}

.menu-buttons a{
  color: #000;
}

.menu ul{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  list-style: none;
}

.menu ul li a{
  text-decoration: none;
  color: #000;
}

.menu ul li a:hover{
  color: rgb(125, 124, 124);
}



.linkedin, .github, .gmail, .btn, .whatsapp{
  transition: .5s;
}

.linkedin:hover{
  color: #0077B5;
}
.gmail:hover{
  color: #FBBC05
}
.github:hover{
  color:  #b0b0b0;
}
.whatsapp:hover{
  color:  #0df805d6;
}

/* Home */

.home{
  margin: auto;
  height: 100vh;
  overflow: hidden;
}
.first-page{
  margin-top: 10vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}


.home-img{
  text-align: center;
  margin-top: 20px;
}

.home-img img{
  height: 180px;
  width: 180px;
  border-radius: 100%;
  cursor: none;
}

.contact{
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}

@media (min-width: 800px) {
  .contact{
    display: none;
  }
}


.my-links{
  width: 30vh;
  margin-top: 10vh;
}

.my-links ul{
  text-align: center;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.my-links ul li{
  text-decoration: none;
}

.my-links ul li a{
  color: #000;
}


h1{
  text-align: center;
}


/* home btn */
.my-home-btns{
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-around;
  width: 40%;
}

@media (max-width: 1000px) {
  .my-home-btns{
    flex-direction: column;
    justify-content: none;
  }  
  .home{
    height: 130vh;
  }
}

button {
  background-color: rgb(245, 245, 245);
  margin: auto;
	width: 150px;
	height: 50px;
  margin-top: 50px;
	cursor: pointer;
	font-size: 18px;
	font-weight: 400;
	color: rgb(0, 0, 118);
	background: white;
	border: 2px solid rgb(220, 220, 220);

	transition: 00ms ease-in-out;
  border-radius: 10px;
}

button:hover {
  border: 2px solid rgb(175, 175, 175);
}

button:focus {
	outline: none;
}

/* about */

.about{
  margin: auto;
  width: 80%;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-text{
  width: 50%;
  text-align: justify;
}

.skills{
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.skills ul{
  color: #04AA6D;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.about-text h2{
  text-align: left;
  color: #0089d3;
}

.about-img{
  width: 25%;
  border-radius: 10px;
}

.about-img img{
  width: 100%;
}

@media (max-width: 800px) {
  .about{
    flex-direction: column-reverse;
  }
  .about-text{
    width: 95%;
  }
  .about-img{
    width: 50%;
    margin: auto;
  }
  .skills{
    width: 100%;
  }
  .skills ul{
    justify-content: space-between;
  }
}

/* projects */

.projects{
  margin: auto;
  min-height: 100vh;
  width: 70%;
}
.projects h2{
  text-align: left;
  color: #0089d3;
}

.projects-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card{
  width: 100%;
  height: 50vh;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}

.reverse{
  display: flex;
  flex-direction: row-reverse;
}

.project-card img{
  max-width: 60%;
  height: 100%;
  border-radius: 10px;
  opacity: .6;
  cursor: pointer;
  transition: .5s;
}
.project-card img:hover{
  opacity: 1;
  z-index: 1000;
}

.project-card-details{
  z-index: 10;
  margin-left: -20px;
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.project-card-title h3{
}


.descRight{
  background-color: #01273b;
  border-radius: 10px;
  padding-top: auto;
  cursor: pointer;
  transition: .5s;
  margin-left: -50px;
}
.descLeft{
  background-color: #01273b;
  border-radius: 10px;
  padding-top: auto;
  cursor: pointer;
  transition: .5s;
  margin-right: -50px;
}

.project-card-description:hover{
  background-color: #0a2e42;
}
.project-card-description:hover p{
  color: white;
}

.project-card-description p{
  text-align: justify;
  color: rgb(159, 159, 159);
  transition: .5s;
  margin: 10px;
}

.project-link{
  margin-top: 10px;
}
.not-online{
  display: none;
}
.project-link a{
  text-decoration: none;
}
.project-url{
  color: #04AA6D;
}
.project-url:hover{
  color: #04cc82;
}

.project-card-techs ul{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
  color: rgb(159, 159, 159);
}

@media (max-width: 800px) {
  .project-card{
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .descRight{
    margin-left: 0;
  }
  .descLeft{
    margin-right: 0;
  }
  .project-card img{
    max-width: 100%;
    opacity: 1;
  }
  .project-card-details{
    margin-left: -20px;
    width: 100%;
    margin: auto;
    
  }
  .project-card-techs{
    width: 100%;
  }
  .project-card-techs ul{
    justify-content: space-between;
    width: 100%;
    margin-left: -50px;
  }
  
}

/* Contact section */
.contact-section{
  margin-top: 30vh;
}
.component-body h2{
  color: #0089d3;
}

.component-body h5{
  color: #04AA6D;
}

.component-body button{
  margin-top: -5px;
  width: 80px;
  background-color: rgb(6, 3, 22);
  color: #04AA6D;
  border: 2px solid #04AA6D;
  transition: .5s;
}
.component-body button:hover{
  background-color: #04AA6D;
  color: white;
}




/* //////////////// */

hr{
  width: 50%;
  text-align: center;
  opacity: 0.2;
}


/* Footer */

.footer{
  width: 100%;
  height: 50px;
  text-align: center;
  opacity: .3;
  position: relative;
  bottom: 0;
  margin-left: auto;
  font-size: smaller;
}

.footer a{
  transition: .5s;
}

.footer a:hover{
  color:rgb(103, 108, 231);
  text-decoration: none;
}

.footer img{
  width: 60px;
  border-radius: 100%;
  opacity: 0.5;
}

.cp-text{  text-shadow: 0 1px rgba(255, 255, 255, 0.1);}

.copyright{
  width: 50%;
  margin-left: auto;
}

/* to top button */

.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style{
  opacity: .3;
  background-color: #000000;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  /* animation: movebtn 3s ease-in-out infinite; */
  /* transition: all .5s ease-in-out; */
  transition: 1s;
}
.icon-style:hover{
  opacity: 1;
}

.coming{
  margin-top: 50vh;
}

/* switchMode */
.dark{
  background-color: rgb(6, 3, 22);
  color: white;
}

.dark h3{
  color: rgb(174, 174, 174);
}

.dark a{
  color: white;
}

.light{
  background-color: rgb(235, 235, 235);
}

.light h3{
  color: #000;
}

.light a{
  color: black;
}

/* Loading page */

.loading{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(6, 3, 22);
}
.loading img{
  width: 50%;
  margin: auto;
}

@media (max-width: 800px) {
  .loading img{
    width: 90%;
  }
}

/* My links */

.my-links{
  height: 300px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 5px;
}

.my-links a{
  margin: auto;
}

.my-links hr{
  margin-top: 80px;
  opacity: 1;
  rotate: 90deg;
  width: 150px;
}

@media (max-width: 800px) {
  .my-links{
    display: none;
  }
}